import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import CustomAlert from './CustomAlert';
import '../css/ContactUs.css';
import emailjs from 'emailjs-com';

function ContactUs() {
  const textContentRef = useRef(null);
  const imageContainerRef = useRef(null);
  const textContent2Ref = useRef(null);
  const requestQuoteRef = useRef(null);
  const textContent3Ref = useRef(null);

  const [selectedForm, setSelectedForm] = useState('information');
  const [quoteStep, setQuoteStep] = useState(1);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    remarks: '',
  });

  const [quoteFormValues, setQuoteFormValues] = useState({
    name: '',
    company: '',
    address: '',
    email: '',
    phone: '',
    pickupDestination: '',
    deliveryLocation: '',
    itemType: '',
    weight: '',
    quantity: '',
    length: '',
    width: '',
    height: '',
  });

  const [quoteFormErrors, setQuoteFormErrors] = useState({
    name: '',
    company: '',
    address: '',
    email: '',
    phone: '',
    pickupDestination: '',
    deliveryLocation: '',
    itemType: '',
    weight: '',
    quantity: '',
    length: '',
    width: '',
    height: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.35,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    const elementsToObserve = [
      textContentRef.current,
      imageContainerRef.current,
      textContent2Ref.current,
      requestQuoteRef.current,
      textContent3Ref.current,
    ];

    elementsToObserve.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  const handleRadioChange = (event) => {
    setSelectedForm(event.target.value);
    setQuoteStep(1);
  };

  // const handleNextStep = (event) => {
  //   event.preventDefault();
  //   setQuoteStep((prevStep) => prevStep + 1);
  // };

  const handlePreviousStep = (event) => {
    event.preventDefault();
    setQuoteStep((prevStep) => prevStep - 1);
  };

  const handleInputFocus = (event) => {
    const { name } = event.target;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleQuoteInputFocus = (event) => {
    const { name } = event.target;
    setQuoteFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'remarks' && value.length > 279) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        remarks: 'Maximum 280 Characters',
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleQuoteInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setQuoteFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    setQuoteFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateQuoteStep1 = () => {
    const errors = {};
    if (!quoteFormValues.name) errors.name = 'Required';
    if (!quoteFormValues.company) errors.company = 'Required';
    if (!quoteFormValues.address) errors.address = 'Required';
    if (!quoteFormValues.email) {
      errors.email = 'Required';
    } else if (!validateEmail(quoteFormValues.email)) {
      errors.email = 'Must be a valid email';
    }
    if (!quoteFormValues.phone) {
      errors.phone = 'Required';
    } else if (!validatePhone(quoteFormValues.phone)) {
      errors.phone = 'Must be a valid number';
    }
    setQuoteFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleQuoteNextStep = (event) => {
    event.preventDefault();
    if (quoteStep === 1) {
      if (validateQuoteStep1()) {
        setQuoteStep((prevStep) => prevStep + 1);
      } else {
        console.log('Step 1 validation failed:', quoteFormErrors);
      }
    } else if (quoteStep === 2) {
      if (validateQuoteStep2()) {
        setQuoteStep((prevStep) => prevStep + 1);
      } else {
        console.log('Step 2 validation failed:', quoteFormErrors);
      }
    }
  };

  const validateQuoteStep2 = () => {
    const errors = {};
    if (!quoteFormValues.pickupDestination) errors.pickupDestination = 'Required';
    if (!quoteFormValues.deliveryLocation) errors.deliveryLocation = 'Required';
    if (!quoteFormValues.itemType) errors.itemType = 'Required';
    if (!quoteFormValues.weight) {
      errors.weight = 'Required';
    } else if (isNaN(quoteFormValues.weight)) {
      errors.weight = 'Must be a valid number';
    }
    if (!quoteFormValues.quantity) {
      errors.quantity = 'Required';
    } else if (isNaN(quoteFormValues.quantity)) {
      errors.quantity = 'Must be a valid number';
    }
    if (!quoteFormValues.length) {
      errors.length = 'Required';
    } else if (isNaN(quoteFormValues.length)) {
      errors.length = 'Must be a valid number';
    }
    if (!quoteFormValues.width) {
      errors.width = 'Required';
    } else if (isNaN(quoteFormValues.width)) {
      errors.width = 'Must be a valid number';
    }
    if (!quoteFormValues.height) {
      errors.height = 'Required';
    } else if (isNaN(quoteFormValues.height)) {
      errors.height = 'Must be a valid number';
    }
    setQuoteFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhone = (phone) => {
    const phonePattern = /^\d+$/;
    return phonePattern.test(phone);
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.name) errors.name = 'Required';
    if (!formValues.company) errors.company = 'Required';
    if (!formValues.email) {
      errors.email = 'Required';
    } else if (!validateEmail(formValues.email)) {
      errors.email = 'Must be a valid email';
    }
    if (!formValues.phone) {
      errors.phone = 'Required';
    } else if (!validatePhone(formValues.phone)) {
      errors.phone = 'Must be a valid number';
    }
    return errors;
  };



  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // 'success' or 'error'




  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const templateParams = {
        name: formValues.name,
        company: formValues.company,
        email: formValues.email,
        phone: formValues.phone,
        remarks: formValues.remarks,
      };

      emailjs.send('service_eqjos09', 'template_6v12fmu', templateParams, '6hHglwaeH0hQQmjcJ')
        .then(() => {
          setAlertTitle('Success');
          setAlertMessage('Thank you! Your information request has been submitted.');
          setAlertType('success');
          setAlertVisible(true);
          setTimeout(() => setAlertVisible(false), 4000);
          setFormValues({
            name: '',
            company: '',
            email: '',
            phone: '',
            remarks: '',
          });
        })
        .catch(() => {
          setAlertTitle('Error');
          setAlertMessage('There was an issue submitting your request. Please try again later.');
          setAlertType('error');
          setAlertVisible(true);
          setTimeout(() => setAlertVisible(false), 4000);
        });
    }
  };


  const handleQuoteSubmit = (event) => {
    event.preventDefault();
    const errors = validateQuoteStep1(); // Add other steps validation as needed
    setQuoteFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const quoteTemplateParams = {
        name: quoteFormValues.name,
        company: quoteFormValues.company,
        address: quoteFormValues.address,
        email: quoteFormValues.email,
        phone: quoteFormValues.phone,
        pickupDestination: quoteFormValues.pickupDestination,
        deliveryLocation: quoteFormValues.deliveryLocation,
        itemType: quoteFormValues.itemType,
        weight: quoteFormValues.weight,
        quantity: quoteFormValues.quantity,
        length: quoteFormValues.length,
        width: quoteFormValues.width,
        height: quoteFormValues.height,
        hydraulicTailgate: quoteFormValues.hydraulicTailgate ? 'Yes' : 'No',
        residentialDelivery: quoteFormValues.residentialDelivery ? 'Yes' : 'No',
        poBoxDelivery: quoteFormValues.poBoxDelivery ? 'Yes' : 'No',
        residentialPickup: quoteFormValues.residentialPickup ? 'Yes' : 'No',
      };

      emailjs.send('service_eqjos09', 'template_1bmxjm7', quoteTemplateParams, '6hHglwaeH0hQQmjcJ')
        .then(() => {
          setAlertTitle('Success');
          setAlertType('success');
          setAlertMessage('Thank you! Your quote request has been submitted.');
          setAlertVisible(true);
          setTimeout(() => setAlertVisible(false), 4000);

          setQuoteFormValues({
            name: '',
            company: '',
            address: '',
            email: '',
            phone: '',
            pickupDestination: '',
            deliveryLocation: '',
            itemType: '',
            weight: '',
            quantity: '',
            length: '',
            width: '',
            height: '',
            hydraulicTailgate: false,
            residentialDelivery: false,
            poBoxDelivery: false,
            residentialPickup: false,
          });

          // Optionally, you can also clear form errors
          setQuoteFormErrors({});
          setQuoteStep(1);
        })
        .catch(() => {
          setAlertTitle('Error');
          setAlertType('error');
          setAlertMessage('There was an issue submitting your quote request. Please try again later.');
          setAlertVisible(true);
          setTimeout(() => setAlertVisible(false), 4000);
        });
    } else {
      setAlertTitle('Error');
      setAlertType('error');
      setAlertMessage('Please correct the errors in the form and try again.');
      setAlertVisible(true);
      setTimeout(() => setAlertVisible(false), 4000);
    }
  };




  return (
    <div className="ContactUs-main-content">
      <Helmet>
        <title>Contact Us - Quan Freight Management | Reliable Freight Solutions Australia-Wide</title>
        <meta
          name="description"
          content="Get in touch with Quan Freight Management for reliable road, rail, and air freight services across Australia. Contact us today to discuss your logistics needs!"
        />
        <meta
          name="keywords"
          content="contact Quan Freight Management, freight enquiries, logistics solutions, Melbourne freight services, Australia-wide freight, road freight, rail freight, air freight, Melbourne to Sydney freight, Melbourne to Brisbane freight, freight quotes, logistics support."
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta name="ICBM" content="-37.8136, 144.9631" />
        <meta property="og:title" content="Contact Us - Reliable Freight Solutions Australia-Wide" />
        <meta
          property="og:description"
          content="Looking for reliable freight services? Contact Quan Freight Management for road, rail, and air freight solutions Australia-wide. We're here to assist with your logistics needs."
        />
        <meta property="og:url" content="https://www.qfmlogistics.com.au/contactus" />
        <meta property="og:image" content="https://www.qfmlogistics.com.au/images/contactus-banner.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - Quan Freight Management" />
        <meta
          name="twitter:description"
          content="Need help with freight services? Contact Quan Freight Management for road, rail, and air freight solutions across Australia. We're ready to assist!"
        />
        <meta name="twitter:image" content="https://www.qfmlogistics.com.au/images/contactus-banner.jpg" />
        <link rel="canonical" href="https://www.qfmlogistics.com.au/contactus" />
      </Helmet>
      {alertVisible && (
        <CustomAlert
          title={alertTitle}
          message={alertMessage}
          type={alertType}
          onClose={() => setAlertVisible(false)}
        />
      )}
      <div className="contactus-content-container">
        <Header isScrolledInitially={true} />
        <div className="contactus-vertical-line"></div>
        <div className="contactus-section-1">
          <div className="contactus-text-content" ref={textContentRef}>
            <h1>Contact Us</h1>
          </div>
        </div>
        <div className="contactus-sections-wrapper">
          <div className="contactus-section-3">
            <h2>Get in touch today</h2>
            <p>For advice, quotes and all your questions, we are available to help at any time.</p>
            <div className="contactus-separation-line"></div>
            <p><i className="fas fa-envelope"></i> contact@qfmlogistics.com.au</p>
          </div>
          <div className="contactus-section-2">
            {selectedForm === 'information' ? (
              <form className="contact-us-form" onSubmit={handleSubmit}>
                <div className="form-row center-row">
                  <div className="form-group radio-group">
                    <label>
                      <input type="radio" id="requestInformation" name="requestType" value="information" defaultChecked onChange={handleRadioChange} />
                      Request Information
                    </label>
                  </div>
                  <div className="form-group radio-group">
                    <label>
                      <input type="radio" id="requestQuote" name="requestType" value="quote" onChange={handleRadioChange} />
                      Request Quote
                    </label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="name">Name {formErrors.name && <span className="label-error">{formErrors.name}</span>}</label>
                    <input type="text" id="name" name="name" value={formValues.name} onChange={handleInputChange} onFocus={handleInputFocus} className={formErrors.name ? 'input-error' : ''} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="company">Company {formErrors.company && <span className="label-error">{formErrors.company}</span>}</label>
                    <input type="text" id="company" name="company" value={formValues.company} onChange={handleInputChange} onFocus={handleInputFocus} className={formErrors.company ? 'input-error' : ''} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="email">Email {formErrors.email && <span className="label-error">{formErrors.email}</span>}</label>
                    <input type="email" id="email" name="email" value={formValues.email} onChange={handleInputChange} onFocus={handleInputFocus} className={formErrors.email ? 'input-error' : ''} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Phone {formErrors.phone && <span className="label-error">{formErrors.phone}</span>}</label>
                    <input type="tel" id="phone" name="phone" value={formValues.phone} onChange={handleInputChange} onFocus={handleInputFocus} className={formErrors.phone ? 'input-error' : ''} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group full-width">
                    <label htmlFor="remarks">Remarks {formErrors.remarks && <span className="error">{formErrors.remarks}</span>}</label>
                    <textarea
                      id="remarks"
                      name="remarks"
                      rows="4"
                      maxLength="280"
                      value={formValues.remarks}
                      onChange={handleInputChange}
                      style={{ resize: 'none' }}
                    ></textarea>
                  </div>
                </div>

                <div className="response-time">
                  We will respond within one business day.
                </div>
                <div className="button-row right-aligned">
                  <button type="submit" className="send-button">Send</button>
                </div>
              </form>
            ) : (
              quoteStep === 1 ? (
                <form className="contact-us-form">
                  <div className="form-row center-row">
                    <div className="form-group radio-group">
                      <label>
                        <input type="radio" id="requestInformation" name="requestType" value="information" onChange={handleRadioChange} />
                        Request Information
                      </label>
                    </div>
                    <div className="form-group radio-group">
                      <label>
                        <input type="radio" id="requestQuote" name="requestType" value="quote" defaultChecked onChange={handleRadioChange} />
                        Request Quote
                      </label>
                    </div>
                  </div>
                  <div className="step-indicator">
                    Step 1 of 3 - Personal information
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="name">Name {quoteFormErrors.name && <span className="label-error">{quoteFormErrors.name}</span>}</label>
                      <input type="text" id="name" name="name" value={quoteFormValues.name} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.name ? 'input-error' : ''} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="company">Company {quoteFormErrors.company && <span className="label-error">{quoteFormErrors.company}</span>}</label>
                      <input type="text" id="company" name="company" value={quoteFormValues.company} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.company ? 'input-error' : ''} />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group full-width">
                      <label htmlFor="address">Address {quoteFormErrors.address && <span className="label-error">{quoteFormErrors.address}</span>}</label>
                      <input type="text" id="address" name="address" value={quoteFormValues.address} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.address ? 'input-error' : ''} />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="email">Email {quoteFormErrors.email && <span className="label-error">{quoteFormErrors.email}</span>}</label>
                      <input type="email" id="email" name="email" value={quoteFormValues.email} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.email ? 'input-error' : ''} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Phone {quoteFormErrors.phone && <span className="label-error">{quoteFormErrors.phone}</span>}</label>
                      <input type="tel" id="phone" name="phone" value={quoteFormValues.phone} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.phone ? 'input-error' : ''} />
                    </div>
                  </div>
                  <div className="button-row">
                    <button type="button" className="next-button right-aligned" onClick={handleQuoteNextStep}>Next</button>
                  </div>
                </form>
              ) : quoteStep === 2 ? (
                <form className="contact-us-form">
                  <div className="form-row center-row">
                    <div className="form-group radio-group">
                      <label>
                        <input type="radio" id="requestInformation" name="requestType" value="information" onChange={handleRadioChange} />
                        Request Information
                      </label>
                    </div>
                    <div className="form-group radio-group">
                      <label>
                        <input type="radio" id="requestQuote" name="requestType" value="quote" defaultChecked onChange={handleRadioChange} />
                        Request Quote
                      </label>
                    </div>
                  </div>
                  <div className="step-indicator">
                    Step 2 of 3 - Quote details
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="pickupDestination">Pickup Destination {quoteFormErrors.pickupDestination && <span className="label-error">{quoteFormErrors.pickupDestination}</span>}</label>
                      <input type="text" id="pickupDestination" name="pickupDestination" value={quoteFormValues.pickupDestination} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.pickupDestination ? 'input-error' : ''} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="deliveryLocation">Delivery Location {quoteFormErrors.deliveryLocation && <span className="label-error">{quoteFormErrors.deliveryLocation}</span>}</label>
                      <input type="text" id="deliveryLocation" name="deliveryLocation" value={quoteFormValues.deliveryLocation} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.deliveryLocation ? 'input-error' : ''} />
                    </div>
                  </div>
                  <div className="form-row three-items">
                    <div className="form-group">
                      <label htmlFor="itemType">Item Type {quoteFormErrors.itemType && <span className="label-error">{quoteFormErrors.itemType}</span>}</label>
                      <select id="itemType" name="itemType" value={quoteFormValues.itemType} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.itemType ? 'input-error' : ''}>
                        <option value="">Item type</option>
                        <option value="Envelope">Envelope</option>
                        <option value="carton">Carton</option>
                        <option value="skid">Skid</option>
                        <option value="pallet">Pallet</option>
                        <option value="crate">Crate</option>
                        <option value="drum">Barrel/Drum</option>
                        <option value="bulkbag">Bulk Bags</option>
                        <option value="container">Container</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="weight">Weight {quoteFormErrors.weight && <span className="label-error">{quoteFormErrors.weight}</span>}</label>
                      <input type="number" id="weight" name="weight" value={quoteFormValues.weight} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.weight ? 'input-error' : ''} placeholder="kg" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="quantity">Quantity {quoteFormErrors.quantity && <span className="label-error">{quoteFormErrors.quantity}</span>}</label>
                      <input type="number" id="quantity" name="quantity" value={quoteFormValues.quantity} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.quantity ? 'input-error' : ''} />
                    </div>
                  </div>
                  <div className="form-row three-items">
                    <div className="form-group">
                      <label htmlFor="length">Length {quoteFormErrors.length && <span className="label-error">{quoteFormErrors.length}</span>}</label>
                      <input type="number" id="length" name="length" value={quoteFormValues.length} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.length ? 'input-error' : ''} placeholder="cm" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="width">Width {quoteFormErrors.width && <span className="label-error">{quoteFormErrors.width}</span>}</label>
                      <input type="number" id="width" name="width" value={quoteFormValues.width} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.width ? 'input-error' : ''} placeholder="cm" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="height">Height {quoteFormErrors.height && <span className="label-error">{quoteFormErrors.height}</span>}</label>
                      <input type="number" id="height" name="height" value={quoteFormValues.height} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.height ? 'input-error' : ''} placeholder="cm" />
                    </div>
                  </div>
                  <div className="button-row">
                    <button type="button" className="previous-button" onClick={handlePreviousStep}>Previous</button>
                    <button type="button" className="next-button" onClick={handleQuoteNextStep}>Next</button>
                  </div>
                </form>
              ) : (
                <form className="contact-us-form" onSubmit={handleQuoteSubmit}>
                  <div className="form-row center-row">
                    <div className="form-group radio-group">
                      <label>
                        <input type="radio" id="requestInformation" name="requestType" value="information" onChange={handleRadioChange} />
                        Request Information
                      </label>
                    </div>
                    <div className="form-group radio-group">
                      <label>
                        <input type="radio" id="requestQuote" name="requestType" value="quote" defaultChecked onChange={handleRadioChange} />
                        Request Quote
                      </label>
                    </div>
                  </div>
                  <div className="step-indicator">
                    Step 3 of 3 - Additional details
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          id="hydraulicTailgate"
                          name="hydraulicTailgate"
                          checked={quoteFormValues.hydraulicTailgate}
                          onChange={handleQuoteInputChange}
                        />
                        Hydraulic Tailgate required
                      </label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          id="residentialDelivery"
                          name="residentialDelivery"
                          checked={quoteFormValues.residentialDelivery}
                          onChange={handleQuoteInputChange}
                        />
                        Is this a residential delivery?
                      </label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          id="poBoxDelivery"
                          name="poBoxDelivery"
                          checked={quoteFormValues.poBoxDelivery}
                          onChange={handleQuoteInputChange}
                        />
                        Is this a PO Box Delivery?
                      </label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          id="residentialPickup"
                          name="residentialPickup"
                          checked={quoteFormValues.residentialPickup}
                          onChange={handleQuoteInputChange}
                        />
                        Is this a residential pickup?
                      </label>
                    </div>
                  </div>

                  <div className="button-row">
                    <button type="button" className="previous-button" onClick={handlePreviousStep}>Previous</button>
                    <button type="submit" className="submit-button">Submit</button>
                  </div>
                </form>
              )
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ContactUs;
