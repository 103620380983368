import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import '../css/AboutUs.css';
import { Link } from 'react-router-dom';
import Image2 from '../images/aboutusimage.webp';

function AboutUs() {
  const textContentRef = useRef(null);
  const imageContainerRef = useRef(null);
  const textContent2Ref = useRef(null);
  const requestQuoteRef = useRef(null);
  const textContent3Ref = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on component mount

    const observerOptions = {
      threshold: 0.35, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    }, observerOptions);

    const elementsToObserve = [
      textContentRef.current,
      imageContainerRef.current,
      textContent2Ref.current,
      requestQuoteRef.current,
      textContent3Ref.current,
    ];

    elementsToObserve.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div className="aboutus-main-content">
      <Helmet>
        <title>About Us - Reliable Road, Rail, and Air Freight Services Australia-Wide | Quan Freight Management</title>
        <meta
          name="description"
          content="Discover Quan Freight Management, your trusted partner for road, rail, and air freight services. Based in Melbourne, we provide efficient freight solutions across Australia, ensuring safe and reliable delivery to all states."
        />
        <meta
          name="keywords"
          content="road freight Melbourne, rail freight Australia, air freight solutions, logistics Australia-wide, Melbourne freight services, interstate freight, reliable delivery, efficient transport, freight management, project logistics, Australia-wide freight services, freight Melbourne to Sydney, Melbourne to Brisbane freight, Melbourne to Perth freight, Melbourne to Adelaide freight."
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta name="ICBM" content="-37.8136, 144.9631" />
        <meta property="og:title" content="About Us - Reliable Road, Rail, and Air Freight Services Australia-Wide" />
        <meta
          property="og:description"
          content="Quan Freight Management specialises in road, rail, and air freight services across Australia. Based in Melbourne, we deliver freight safely and on time to all states."
        />
        <meta property="og:url" content="https://www.qfmlogistics.com.au/aboutus" />
        <meta property="og:image" content="https://www.qfmlogistics.com.au/images/aboutus-banner.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - Reliable Freight Services Across Australia" />
        <meta
          name="twitter:description"
          content="Learn more about Quan Freight Management, offering road, rail, and air freight solutions from Melbourne to all Australian states. Reliable, efficient, and customer-focused."
        />
        <meta name="twitter:image" content="https://www.qfmlogistics.com.au/images/aboutus-banner.jpg" />
        <link rel="canonical" href="https://www.qfmlogistics.com.au/aboutus" />
      </Helmet>
      <div className="aboutus-content-container">
        <Header isScrolledInitially={true} />
        <div className="aboutus-vertical-line"></div>
        <div className="aboutus-section-1">
          <div className="aboutus-text-content" ref={textContentRef}>
            <h1>About Us</h1>
            <p>
              At Quan Freight Management, we provide reliable freight services to ensure your goods are transported safely and efficiently across Australia. Our expert team and state-of-the-art technology guarantee timely delivery and cost-effective solutions.
            </p>
          </div>
          <div className="aboutus-image-container" ref={imageContainerRef}>
            <img src={Image2} alt="Freight services" />
          </div>
        </div>
        <div className="aboutus-sections-wrapper">
          <div className="aboutus-section-2">
            <div className="aboutus-text-content-2" ref={textContent2Ref}>
              <h2>History of the Directors</h2>
              <p>
                Chris and John first met over a decade ago while working at a local transport company. Their shared passion for the logistics industry and a mutual desire to create something of their own led them to form a strong professional bond. With their combined extensive experience in local, domestic, and international freight, they decided to embark on a journey to start their own transport business. Their deep understanding of the industry and shared vision laid the foundation for what would become Quan Freight Management.
              </p>
            </div>
          </div>
          <div className="aboutus-section-3">
            <div className="aboutus-text-content-3" ref={textContent3Ref}>
              <h2>Our Offer to You</h2>
              <h3>Your shipment is always our first priority</h3>
              <ul>
                <li>Reliable and timely delivery across Australia</li>
                <li>Comprehensive logistics solutions tailored to your needs</li>
                <li>State-of-the-art technology for efficient tracking</li>
                <li>Cost-effective transport options to optimize your budget</li>
                <li>Experienced and professional team dedicated to excellence</li>
              </ul>
            </div>
            <Link to="/contactus" className="aboutus-linkz">
              <div className="aboutus-request-quote" ref={requestQuoteRef}>
                Request Quote
              </div>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
