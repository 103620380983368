import React, { useEffect, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import '../css/Technology.css';
import { Link } from 'react-router-dom';
import Image3 from '../images/technology.webp';

function Tech() {
    window.scrollTo(0, 0);
    const textContentRef = useRef(null);
    const imageContainerRef = useRef(null);
    const textContent2Ref = useRef(null);
    const requestQuoteRef = useRef(null);
    const textContent3Ref = useRef(null);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1, // Adjust this threshold as needed
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target); // Stop observing once it's visible
                }
            });
        }, observerOptions);

        [textContentRef, imageContainerRef, textContent2Ref, requestQuoteRef, textContent3Ref].forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            [textContentRef, imageContainerRef, textContent2Ref, requestQuoteRef, textContent3Ref].forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    return (
        <div className="tech-main-content">
            <Helmet>
                <title>Advanced Freight Management Technology | Quan Freight Management</title>
                <meta
                    name="description"
                    content="Discover how Quan Freight Management leverages cutting-edge technology to enhance visibility, reduce waste, mitigate risks, and drive cost savings in freight operations across Australia."
                />
                <meta
                    name="keywords"
                    content="freight management technology, logistics technology, supply chain innovation, real-time tracking, risk mitigation, cost savings, Quan Freight Management, Australia freight technology, transport management systems, logistics optimization."
                />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta name="ICBM" content="-37.8136, 144.9631" />
                <meta property="og:title" content="Advanced Freight Management Technology | Quan Freight Management" />
                <meta
                    property="og:description"
                    content="Learn how Quan Freight Management utilizes state-of-the-art technology to improve freight operations, ensuring efficient and reliable logistics solutions across Australia."
                />
                <meta property="og:url" content="https://www.qfmlogistics.com.au/technology" />
                <meta property="og:image" content="https://www.qfmlogistics.com.au/images/technology-banner.jpg" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Advanced Freight Management Technology" />
                <meta
                    name="twitter:description"
                    content="Explore how Quan Freight Management's technological innovations enhance logistics efficiency and reliability across Australia."
                />
                <meta name="twitter:image" content="https://www.qfmlogistics.com.au/images/technology-banner.jpg" />
                <link rel="canonical" href="https://www.qfmlogistics.com.au/technology" />
            </Helmet>
            <div className="tech-content-container">
                <Header isScrolledInitially={true} />
                <div className="tech-vertical-line"></div>
                <div className="tech-section-1">
                    <div className="tech-text-content" ref={textContentRef}>
                        <h1>Technology</h1>
                        <p>At Quan Freight Management, we utilize cutting-edge technology to provide reliable freight services, ensuring your goods are transported safely and efficiently across Australia. Our expert team and state-of-the-art technology guarantee timely delivery and cost-effective solutions.</p>
                    </div>
                    <div className="tech-image-container" ref={imageContainerRef}>
                        <img src={Image3} alt="Technology" />
                    </div>
                </div>
                <div className="tech-sections-wrapper">
                    <div className="tech-section-2">
                        <div className="tech-text-content-2" ref={textContent2Ref}>
                            <h2>Machship</h2>
                            <p>At Quan Freight Management, we leverage the power of Machship, a cloud-based freight management platform, to streamline our logistics and enhance service delivery. Machship provides us with advanced tools that enable efficient management of freight operations, from order creation to final delivery.</p>
                            <ul>
                                <li><b>End-to-End Visibility:</b> offers real-time tracking and visibility across all freight movements, allowing us to monitor shipments closely and keep our clients informed at every stage.</li>
                                <li><b>Automated Processes:</b> automating complex tasks such as carrier selection, rate calculation, and consignment creation, reducing human error and increasing operational efficiency.</li>
                                <li><b>Seamless Integration:</b> seamlessly integrates with our existing systems, ensuring that all logistics processes are connected and data flows smoothly between departments.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="tech-section-3">
                        <div className="tech-text-content-3" ref={textContent3Ref}>
                            <h2>Commitment to Innovation</h2>
                            <h3>Always pushing the boundaries of what's possible</h3>
                            <ul>
                                <li>State-of-the-art tracking systems</li>
                                <li>Automated logistics processes</li>
                                <li>Data-driven decision-making</li>
                                <li>Continuous improvement and innovation</li>
                                <li>Committed to sustainability and efficiency</li>
                            </ul>
                        </div>
                        <Link to="/contactus" className="tech-link"><div className="tech-request-quote" ref={requestQuoteRef}> Request Quote </div></Link>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Tech;
